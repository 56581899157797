<template>
  <v-app>
    <section class="my-6">
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Xичээлийн ачаалалын xяналт
              </h5>
              <p class="text-sm text-body mb-0">
                Багш бүрийн үүссэн xичээлийг xянаx
              </p>
              <v-checkbox
                v-model="withLesson"
                :label="
                  withLesson
                    ? 'Хичээлтэй багш нар '
                    : 'Хичээл оруулаaгүй багш нар '
                "
              ></v-checkbox>
            </div>
          </div>
        </div>

        <v-card-title>
          <v-row>
            <!-- <v-col cols="6" v-if="departments">
                <v-select
                  return-object
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  v-model="filterSelectedDepartment"
                  label="ЗАН сонгоx"
                >
                </v-select>
              </v-col> -->
          </v-row>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          v-if="filteredTeachers"
          :headers="headers"
          :items="filteredTeachers"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.index }}</td>
              <td>
                <v-avatar rounded :size="36" class="my-0 me-5">
                  <!--               
                <span class="white--text">{{
                  item.name.substring(0, 2).toUpperCase()
                }}</span>
                 -->
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <td>
                {{ props.item["name"] }}
              </td>
              <td>{{ props.item.numberOfLessons }}</td>
              <td
                class="selectedLesson"
                v-for="(lesson, lindex) in props.item.teachingLessons"
                :key="'lesson' + lindex"
              >
                <v-col class="pa-0" @click="_detailLesson(lesson)">
                  <p class="mb-0" style="color: #4c8bf5">
                    <span style="color: #bbb">{{ lindex + 1 }}. </span
                    >{{ lesson.name }}
                  </p>
                  <v-row class="ma-0">
                    <small
                      v-for="(classGroup, cindex) in lesson.teachingClass"
                      :key="'classGroup' + cindex"
                      >{{ classGroup }}</small
                    >
                  </v-row>
                </v-col>
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card>
    </section>
  </v-app>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    search: null,
    users: null,
    withLesson: true,
    headers: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        fixed: true,
        width: "1%",
      },

      {
        text: "Xт",
        align: "start",
        sortable: true,
        value: "actions",
      },
    ],
  }),
  props: {},
  computed: {
    ...sync("*"),
    filteredTeachers() {
      var list = [];
      var indexx = 0;
      if (this.users) {
        for (var user of this.users) {
          if (user.lastName && user.lastName.length > 0) {
            user.name = user.lastName[0] + "." + user.firstName;
          } else {
            user.name = user.firstName;
          }
          if (this.withLesson) {
            if (user.teachingLessons && user.teachingLessons.length >= 0) {
              indexx++;
              user.index = indexx;
              list.push(user);
            }
          } else {
            indexx++;
            user.index = indexx;
            list.push(user);
          }
        }
      }
      return list;
    },
  },
  created() {
    if (this.userData.school)
      this.userData.school.ref
        .collection("teachers")
        .onSnapshot((querySnapshot) => {
          this.users = [];
          var counter = 0;
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            counter++;
            item.index = counter;
            item.avatar = require("@/assets/img/team-2.jpg");
            // if(item.email) item.ref.update({email: item.email.toLowerCase()})

            if (item.firstName && item.firstName.length > 0) {
              if (item.firstName[0].toUpperCase() != item.firstName[0]) {
                item.firstName =
                  item.firstName.charAt(0).toUpperCase() +
                  item.firstName.slice(1);
                item.ref.update({ firstName: item.firstName });
              }
            }
            if (item.lastName && item.lastName.length > 0) {
              if (item.lastName[0].toUpperCase() != item.lastName[0]) {
                item.lastName =
                  item.lastName.charAt(0).toUpperCase() +
                  item.lastName.slice(1);
                item.ref.update({ lastName: item.lastName });
              }
            }

            item.teachingLessons = null;
            this.userData.school.ref
              .collection("lessons")
              .where("teacherRef", "==", item.ref)
              .where("deleted", "==", false)
              .get()
              .then((docs) => {
                if (!docs.empty) {
                  item.teachingLessons = [];
                  item.numberOfLessons = docs.size;
                  docs.forEach((doc) => {
                    let lesson = doc.data();
                    lesson.ref = doc.ref;
                    lesson.id = doc.id;
                    if (lesson.classGroups) {
                      lesson.teachingClass = [];
                      lesson.classGroups.forEach((classGroup) => {
                        lesson.teachingClass.push(
                          classGroup.departmentName +
                            classGroup.classGroupName.toUpperCase() +
                            ","
                        );
                      });
                    }

                    item.teachingLessons.push(lesson);
                  });
                }
              });

            this.users.push(item);
          });
        });
  },
  methods: {
    _detailLesson(item) {
      this.$router.push({
        name: "PageLesson",
        params: {
          path: item.ref.path,
        },
      });
    },
  },
};
</script>
<style>
.selectedLesson {
  cursor: pointer;
  height: 100%;
}
.selectedLesson :hover {
  background-color: #f5f591 !important;
  height: 100%;
}
</style>
